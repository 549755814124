<template>
  <div class="importorg-wrapper">
    <div class="save-wrapper">
      <v-alert
        dense
        outlined
        type="error"
        v-if="verifyResult"
        style="margin-right:100px;margin-bottom:0!important;margin-top:-6px;"
      >
        请将以下列表填写完整
      </v-alert>
    </div>
    <v-form :model="form" v-model="valid" ref="form" class="mt-4">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left pl-4">
                身份源属性
              </th>
              <th class="text-left pl-4">
                转换方式
              </th>
              <th class="text-left pl-4">
                更新策略
              </th>
              <th class="text-left pl-4">
                本地员工属性
              </th>
              <th class="text-center pl-2">
                {{ $t("Actions") }}
              </th>
            </tr>
          </thead>
          <tbody v-if="mappings.length > 0" style="width:100%">
            <tr v-for="(item, index) in mappings" :key="item.id">
              <td class="pl-4" style="border-bottom: 0 none;width:24%">
                <v-combobox
                  v-model="item.claim"
                  :items="allSelectDatas[allDatas.type]"
                  :item-value="item.claim"
                  persistent-hint
                  hint="身份源属性"
                  :rules="rules.notNullRules"
                  @input.native="
                    item.claim = $event.target.value;
                    hideDropdown = true;
                  "
                  :menu-props="{ ...(hideDropdown && { value: false }) }"
                  @click="hideDropdown = false"
                >
                </v-combobox>
              </td>
              <td class="pl-4" style="border-bottom: 0 none;width:24%">
                <v-select
                  v-model="item.type"
                  :items="transformMode"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  hint="转换方式"
                  :rules="rules.notNullRules"
                ></v-select>
              </td>
              <td class="pl-4" style="border-bottom: 0 none;width:24%">
                <v-select
                  v-model="item.merge"
                  :items="executionMode"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  hint="更新策略"
                  :rules="rules.notNullRules"
                ></v-select>
              </td>
              <td class="pl-4" style="border-bottom: 0 none;width:24%">
                <v-select
                  v-model="item.attribute"
                  :items="attributes"
                  item-text="display_name"
                  item-value="name"
                  persistent-hint
                  hint="本地员工属性"
                  :rules="rules.notNullRules"
                  :error-messages="item.exist ? '已存在' : ''"
                ></v-select>
              </td>
              <td class="pl-2 text-center" style="border-bottom: 0 none">
                <v-btn
                  icon
                  elevation="0"
                  color="#c0c0c0"
                  @click.stop="toDelete(item, index)"
                >
                  <v-icon>
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="text-center">
              <td
                colspan="5"
                style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
              >
                暂无数据
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-form>
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import { checkRepeatName, checkRepeatAttribute } from "@/util/util";
import bus from "@/util/eventBus";
import ConfirmBox from "@/components/ConfirmBox";

// 企业微信身份源属性
let claimObj = {
  __name__: "原系统用户id",
  userid: "员工ID",
  userclaim: "员工声明",
  name: "员工名称",
  mobile: "手机号码",
  // department: "员工所属部门",
  // order: "部门内的排序值",
  position: "职务信息",
  gender: "性别",
  email: "邮箱",
  // is_leader_in_dept: "是否为上级",
  // avatar: "头像",
  thumb_avatar: "头像缩略图",
  telephone: "座机",
  alias: "别名",
  tag_ids: "标签标识",
  // extattr: "扩展属性",
  // status: "激活状态",
  qr_code: "员工个人二维码",
  external_profile: "员工对外属性",
  external_position: "对外职务",
  nickname: "对外展示视频号名称",
  address: "地址",
  disabled: "禁用",
  open_userclaim: "全局唯一",
  main_department: "主部门",
  category: "员工类别",
  expiry_date: "过期日期",
  username: "员工名称",
  phone: "员工手机号"
};

// 钉钉身份源属性
let dingObj = {
  __name__: "原系统用户id",
  userid: "员工ID",
  name: "员工名称",
  mobile: "手机号",
  hide_mobile: "是否号码隐藏",
  telephone: "分机号",
  job_number: "员工编号",
  manager_userid: "直属主管的userId",
  title: "职位",
  email: "员工邮箱",
  org_email: "员工的企业邮箱",
  org_email_type: "员工的企业邮箱类型",
  work_place: "办公地点",
  remark: "备注",
  dept_id_list: "所属部门id列表",
  // dept_order_list: "员工在对应的部门中的排序",
  dept_title_list: "员工在对应的部门中的职位",
  extension: "扩展属性",
  senior_mode: "是否开启高管模式",
  hired_date: "入职时间",
  login_email: "登录邮箱",
  exclusive_account: "是否专属帐号",
  exclusive_account_type: "专属帐号类型",
  loginId: "钉钉专属帐号登录名",
  init_password: "钉钉专属帐号初始密码",
  role_ids: "角色ID列表"
};

// 飞书身份源属性
let larkObj = {
  __name__: "原系统用户id",
  union_id: "用户的union_id",
  user_id: "租户内用户的唯一标识",
  open_id: "用户的open_id",
  name: "用户名",
  en_name: "英文名",
  email: "邮箱",
  mobile: "手机号",
  mobile_visible: "手机号码可见性",
  gender: "性别",
  avatar: "用户头像信息",
  avatar_72: "72*72像素头像链接",
  avatar_240: "240*240像素头像链接",
  avatar_640: "640*640像素头像链接",
  avatar_origin: "原始头像链接",
  status: "用户状态",
  is_frozen: "是否暂停",
  is_resigned: "是否离职",
  is_activated: "是否激活",
  department_ids: "用户所属部门的ID列表",
  leader_user_id: "用户的直接主管的用户ID",
  city: "城市",
  country: "国家或地区",
  work_station: "工位",
  join_time: "入职时间",
  is_tenant_manager: "是否是租户超级管理员",
  employee_no: "工号",
  employee_type: "员工类型",
  orders: "用户排序信息",
  department_id: "排序信息对应的部门ID",
  user_order: "用户在其直属部门内的排序",
  department_order: "用户所属的多个部门间的排序",
  custom_attrs: "自定义字段",
  type: "自定义字段类型",
  enterprise_email: "企业邮箱",
  job_title: "职务",
  disabled: "是否禁用"
};

// CAS、OAUTH2身份源属性
let otherObj = {
  __name__: "原系统用户id"
};

let claimDatas = [
  "员工ID",
  "员工声明",
  "员工名称",
  "手机号码",
  // "员工所属部门",
  // "部门内的排序值",
  "职务信息",
  "性别",
  "邮箱",
  // "是否为上级",
  // "头像",
  "头像缩略图",
  "座机",
  "别名",
  "标签标识",
  // "扩展属性",
  // "激活状态",
  "员工个人二维码",
  "员工对外属性",
  "对外职务",
  "对外展示视频号名称",
  "地址",
  "禁用",
  "全局唯一",
  "主部门",
  "员工类别",
  "过期日期",
  "员工名称",
  "员工手机号"
];

let dingDatas = [
  "原系统用户id",
  "员工ID",
  "员工名称",
  "手机号",
  "是否号码隐藏",
  "分机号",
  "员工编号",
  "直属主管的userId",
  "职位",
  "员工邮箱",
  "员工的企业邮箱",
  "员工的企业邮箱类型",
  "办公地点",
  "备注",
  "所属部门id列表",
  "员工在对应的部门中的职位",
  "扩展属性",
  "是否开启高管模式",
  "入职时间",
  "登录邮箱",
  "是否专属帐号",
  "专属帐号类型",
  "钉钉专属帐号登录名",
  "钉钉专属帐号初始密码",
  "角色ID列表"
];

let larkDatas = [
  "原系统用户id",
  "用户的union_id",
  "租户内用户的唯一标识",
  "用户的open_id",
  "用户名",
  "英文名",
  "邮箱",
  "手机号",
  "手机号码可见性",
  "性别",
  "用户头像信息",
  "72*72像素头像链接",
  "240*240像素头像链接",
  "640*640像素头像链接",
  "原始头像链接",
  "用户状态",
  "是否暂停",
  "是否离职",
  "是否激活",
  "用户所属部门的ID列表",
  "用户的直接主管的用户ID",
  "城市",
  "国家或地区",
  "工位",
  "入职时间",
  "是否是租户超级管理员",
  "工号",
  "员工类型",
  "用户排序信息",
  "排序信息对应的部门ID",
  "用户在其直属部门内的排序",
  "用户所属的多个部门间的排序",
  "自定义字段",
  "自定义字段类型",
  "企业邮箱",
  "职务",
  "是否禁用"
];

let otherDatas = ["原系统用户id"];

export default {
  name: "AttributeMapping",
  props: {
    allDatas: {
      type: Object
    }
  },
  data() {
    return {
      hideDropdown: false,
      select: {
        id: "DIRECT",
        name: "直接映射"
      },
      transformMode: [
        {
          id: "DIRECT",
          name: "直接映射"
        }
      ],
      select1: {
        id: "CREATE_ONLY",
        name: "仅创建"
      },
      executionMode: [
        {
          id: "CREATE_ONLY",
          name: "仅创建"
        },
        {
          id: "CREATE_UPDATE",
          name: "创建并更新"
        }
      ],
      allSelectDatas: {
        WECHAT_ENTERPRISE: claimDatas,
        CAS: otherDatas,
        OAUTH2: otherDatas,
        CAS_REST_API: otherDatas,
        LDAP: otherDatas,
        FNU_LDAP: otherDatas,
        HTTP_BASIC: otherDatas,
        DING_TALK: dingDatas,
        LARK: larkDatas
      },
      allObjs: {
        WECHAT_ENTERPRISE: claimObj,
        CAS: otherObj,
        OAUTH2: otherObj,
        CAS_REST_API: otherObj,
        LDAP: otherObj,
        FNU_LDAP: otherObj,
        HTTP_BASIC: otherObj,
        DING_TALK: dingObj,
        LARK: larkObj
      },
      mappings: [],
      attributes: [],
      attribute: "",
      verifyResult: false,
      valid: true,
      form: {},
      rules: {
        notNullRules: [v => !!v || "此项为必填项"]
      }
    };
  },
  created() {
    this.getAttributes();
    this.getCurIdData();
  },
  methods: {
    getCurIdData() {
      let mapping_options = this.allDatas.mapping_options;
      if (
        mapping_options &&
        mapping_options.account &&
        mapping_options.account[0] &&
        mapping_options.account[0].mappings
      ) {
        this.mappings = this.allDatas.mapping_options.account[0].mappings;
        let typeSelectObj = this.allObjs[this.allDatas.type];
        if (this.mappings && this.mappings.length > 0) {
          this.mappings.map(item => {
            item.claim = typeSelectObj[item.claim]
              ? typeSelectObj[item.claim]
              : item.claim;
            return item;
          });
        }
      }
    },
    getAttributes() {
      this.$http
        .get("api/attribute/account/list")
        .delegateTo(api_request)
        .then(data => {
          this.attributes = data.attributes;
          this.attribute = this.attributes.filter(item => {
            return this.attribute === item;
          })[0];
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    doCreate() {
      let newItem = {
        attribute: "",
        claim: "",
        merge: "CREATE_ONLY",
        options: {},
        type: "DIRECT"
      };
      this.mappings.unshift(newItem);
    },
    saveMapping() {
      if (this.$refs.form.validate() && checkRepeatName(this.mappings)) {
        let newMapDatas = [];
        let typeSelectObj = this.allObjs[this.allDatas.type];
        this.mappings.forEach(item => {
          let obj = {
            attribute: item.attribute,
            claim: item.claim,
            merge: item.merge,
            options: item.options,
            type: item.type
          };
          newMapDatas.push(obj);
        });
        let lastestMaps = newMapDatas.map(item => {
          for (let key in typeSelectObj) {
            item.claim = typeSelectObj[key] === item.claim ? key : item.claim;
          }
          return item;
        });
        let params = {
          mappings: lastestMaps
        };
        this.$http
          .patch(
            `api/source/${this.$route.params.id}/mapping_options/ACCOUNT/mappings`,
            params
          )
          .delegateTo(api_request)
          .then(() => {
            this.getCurIdData();
            bus.$emit("refresh");
            return "保存成功";
          })
          .catch(({ code, message }) => {
            throw `保存失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      } else if (!checkRepeatName(this.mappings)) {
        this.mappings = checkRepeatAttribute(this.mappings);
        this.$snackbar.showMessage({
          content: "本地属性不允许重复映射",
          isError: true
        });
      } else {
        this.$snackbar.showMessage({
          content: "列表未填写完整",
          isError: true
        });
      }
    },
    toDelete(curItem, curIndex) {
      if (curItem.attribute === "external_id") {
        return this.$snackbar.showMessage({
          content: `全局唯一ID不可删除`,
          isError: false
        });
      } else {
        this.$refs.confirm
          .showConfirm({
            contentText: `您确认要删除${curItem.claim}？`,
            callback: () => {
              this.mappings = this.mappings.filter((item, index) => {
                return curIndex !== index;
              });
              return new Promise(resolve => resolve());
            }
          })
          .catch(() => {
            return;
          });
      }
    }
  },
  watch: {
    allDatas() {
      this.getCurIdData();
    }
  },
  components: {
    ConfirmBox
  }
};
</script>

<style lang="less" scoped>
.importorg-wrapper {
  width: 100%;
  .save-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .table-wrapper {
    margin-top: 10px;
  }
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
