<template>
  <div class="linkorigin-wrapper">
    <Breadcrumbs />
    <div class="content-wrapper">
      <TabList @tabEvent="tabEvent($event)" :type="type" :tabList="tabList">
        <template v-slot:action-buttons v-if="tabId === 1">
          <v-btn
            depressed
            color="primary"
            @click.stop="$refs.component.doCreate()"
            style="margin-right:20px"
            >新增</v-btn
          >
          <v-btn
            depressed
            color="primary"
            @click.stop="$refs.component.saveMapping()"
            >保存</v-btn
          >
        </template>
      </TabList>
      <component
        :is="curCom"
        :allDatas="allDatas"
        :type="type"
        ref="component"
      />
    </div>
  </div>
</template>

<script>
import { api_request } from "@/util/network";

import Breadcrumbs from "@/components/Breadcrumbs";
import TabList from "@/components/TabList";
import DataSource from "./component/DataSource";
import AttributeMapping from "./component/AttributeMapping";
import DataSourceSync from "./component/DataSourceSync";
// import MappingAccount from "./components/MappingAccount";
import bus from "@/util/eventBus";
import { mapMutations } from "vuex";

const displayDataSourceSync = {
  LDAP: true,
  WECHAT_ENTERPRISE: true,
  DING_TALK: true,
  LARK: true
};

const COMPONENTS = [DataSource, AttributeMapping, DataSourceSync];

export default {
  name: "LinkOrigin",
  data() {
    return {
      tabId: 0,
      type: null,
      allDatas: {},
      curCom: ""
    };
  },
  mounted() {
    this.getCurIdData(this.$route.params.id);
    bus.$off("refresh").$on("refresh", () => {
      this.getCurIdData(this.$route.params.id);
    });
  },
  methods: {
    ...mapMutations(["change_breadcrumbs"]),
    tabEvent(id) {
      this.tabId = id;
    },
    getCurIdData(id) {
      this.$http
        .get(`api/source/${id}/describe`)
        .delegateTo(api_request)
        .then(data => {
          this.type = data.type;
          this.allDatas = data;
          this.change_breadcrumbs([
            { text: "Identity Source" },
            { text: data.type }
          ]);
          this.curCom = COMPONENTS[this.tabId];
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    log() {
      console.log(arguments);
    }
  },
  watch: {
    tabId(v) {
      this.curCom = COMPONENTS[v];
    }
  },
  computed: {
    tabList() {
      if (!this.type) return [];
      let arr = [];
      if (this.type !== "WEBVPN") {
        arr = [
          {
            id: 0,
            name: "认证配置"
          },
          {
            id: 1,
            name: "属性映射"
          }
        ];
      } else {
        arr = [
          {
            id: 0,
            name: "认证配置"
          }
        ];
      }

      return displayDataSourceSync[this.type]
        ? arr.concat({
            id: 2,
            name: "数据源同步"
          })
        : arr;
    }
  },
  components: {
    Breadcrumbs,
    TabList,
    DataSource,
    AttributeMapping,
    DataSourceSync
    // MappingAccount,
  }
};
</script>

<style lang="less" scoped>
.linkorigin-wrapper {
  .main-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0 27px;
    margin-bottom: 30px;
  }
}
</style>
