<template>
  <div>
    <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{
              `${$t("source.Edit {type} account config", {
                type: typeTitles[allDatas.type]
              })}：${allDatas.name}`
            }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-form class="pa-4" ref="form" :model="form" v-model="valid">
        <v-alert outlined dense type="info" class="text-left body-2 mb-8">
          账户同步列表项
        </v-alert>
        <v-row
          v-if="
            !(
              allDatas.type === 'LDAP' ||
              allDatas.type === 'WECHAT_ENTERPRISE' ||
              allDatas.type === 'DING_TALK' ||
              allDatas.type === 'LARK'
            )
          "
        >
          <v-col cols="3" class="mt-2">
            <v-subheader><span class="red--text">*</span>根组织</v-subheader>
          </v-col>
          <v-col cols="9">
            <v-select
              :items="departmentTree"
              :label="default_department_id ? '' : '请选择'"
              item-text="name"
              item-value="id"
              v-model="default_department_id"
              @change="getvalue_typeSelected(default_department_id)"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="mt-2">
            <v-subheader><span class="red--text">*</span> 白名单</v-subheader>
          </v-col>
          <v-col cols="9" class="mt-5">
            {{ whiteList }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="mt-2">
            <v-subheader
              ><span class="red--text">*</span> 用户匹配策略</v-subheader
            >
          </v-col>
          <v-col cols="9" class="mt-5">
            <v-icon @click="add">mdi-plus</v-icon>
          </v-col>
        </v-row>
        <v-row v-for="(item, index) in matchers" :key="`${item.name}_${index}`">
          <v-col cols="3" class="mt-2">
            <v-subheader></v-subheader>
          </v-col>
          <v-col cols="8" class="d-flex align-center">
            <v-combobox
              :label="matchers.length === 0 ? '请完善' : ''"
              :items="field_names"
              v-model="item.claim"
              :item-value="item.claim"
              placeholder="身份源属性名"
              persistent-hint
              hint="身份源属性名"
              :rules="rules.notNullRules"
              @input.native="
                item.claim = $event.target.value;
                hideDropdown = true;
              "
              :menu-props="{ ...(hideDropdown && { value: false }) }"
              @click="hideDropdown = false"
            ></v-combobox>
            <v-select
              class="ml-2 mt-3"
              :items="match_types"
              v-model="item.matcher"
              item-text="name"
              item-value="id"
              dense
              placeholder="请选择匹配方式"
              persistent-hint
              hint="请选择匹配方式"
              :rules="rules.notNullRules"
            ></v-select>
            <v-icon color="red" @click="remove(item, index)"
              >mdi-trash-can-outline</v-icon
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="mt-2">
            <v-subheader
              ><span class="red--text">*</span>允许同步新用户</v-subheader
            >
          </v-col>
          <v-col cols="9">
            <v-checkbox class="v-label" v-model="create_entity"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="mt-2">
            <v-subheader
              ><span class="red--text">*</span>更新用户属性</v-subheader
            >
          </v-col>
          <v-col cols="9">
            <v-checkbox class="v-label" v-model="update_entity"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="mt-2">
            <v-subheader><span class="red--text">*</span>过期用户</v-subheader>
          </v-col>
          <v-col cols="9">
            <v-radio-group v-model="stale_entity" row>
              <v-radio label="保留用户" value="keep"></v-radio>
              <v-radio label="禁用用户" value="disable"></v-radio>
              <v-radio label="删除用户" value="delete" disabled></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="3">
            <v-subheader>缺省用户组</v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field v-model="default_group_name"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <!-- <v-col class="d-flex" cols="3"></v-col> -->
          <v-col cols="12" class="text-center">
            <v-btn elevation="4" medium color="primary" @click="save()"
              >提交</v-btn
            >
            <v-btn
              v-if="0"
              class="ml-6"
              elevation="4"
              medium
              color="gary"
              @click="reset()"
              >重置</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-navigation-drawer>
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import ConfirmBox from "@/components/ConfirmBox";
import bus from "@/util/eventBus";

let field_namesObj = {
  __name__: "原系统用户id",
  mobile: "手机号"
};

export default {
  name: "AccountConfigEditor",
  inject: ["reload"],
  props: {
    allDatas: {
      type: Object
    },
    departmentTree: {
      type: Array
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      default_group_name: "",
      hideDropdown: true,
      default_department_id: "2",
      whiteList: "默认全部放行",
      create_entity: true,
      update_entity: true,
      stale_entity: "keep",
      valid: true,
      form: {},
      typeTitles: {
        WECHAT_ENTERPRISE: "企业微信",
        CAS: "CAS",
        OAUTH2: "OAUTH2",
        CAS_REST_API: "CAS REST API",
        LDAP: "LDAP",
        HTTP_BASIC: "HTTP_BASIC",
        DING_TALK: "钉钉",
        LARK: "飞书"
      },
      rules: {
        notNullRules: [v => !!v || "此项为必填项"]
      },
      match_types: [
        {
          id: "mobilephone",
          name: "手机号匹配"
        },
        {
          id: "external_id",
          name: "用户ID匹配"
        }
      ],
      field_names: ["原系统用户id", "手机号"],
      matchers: []
    };
  },
  created() {
    if (this.type !== "create") {
      this.fetchData();
    }
  },
  methods: {
    getvalue_typeSelected(val) {
      this.default_department_id = val;
    },
    save() {
      if (this.$refs.form.validate()) {
        let strategies = {};

        if (
          !(
            this.type === "LDAP" ||
            this.type === "WECHAT_ENTERPRISE" ||
            this.type === "DING_TALK" ||
            this.type === "LARK"
          )
        ) {
          strategies = {
            default_department_id: this.default_department_id,
            create_entity: this.create_entity === true ? "create" : null,
            update_entity: this.update_entity === true ? "update" : null,
            stale_entity: this.stale_entity,
            default_group_name: this.default_group_name
          };
        } else {
          strategies = {
            create_entity: this.create_entity === true ? "create" : null,
            update_entity: this.update_entity === true ? "update" : null,
            stale_entity: this.stale_entity,
            default_group_name: this.default_group_name
          };
        }

        let newArr = [];
        this.matchers.forEach(item => {
          let obj = {
            claim: item.claim,
            matcher: item.matcher,
            options: item.options
          };
          newArr.push(obj);
        });

        let metcherDatas = newArr.map(item => {
          for (let key in field_namesObj) {
            item.claim = field_namesObj[key] === item.claim ? key : item.claim;
          }
          return item;
        });

        let payloadMatchers = {
          matchers: metcherDatas,
          strategies
        };

        this.$http
          .patch(
            `api/source/${this.$route.params.id}/mapping_options/ACCOUNT/strategies`,
            payloadMatchers
          )
          .delegateTo(api_request)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            bus.$emit("refresh");
            return "更新成功";
          })
          .catch(({ code, message }) => {
            throw `更新失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    fetchData() {
      if (this.allDatas.mapping_options.account.length > 0) {
        if (this.allDatas.mapping_options.account.length > 0) {
          let departmentStrategy = this.allDatas.mapping_options.account[0]
            .strategy;
          this.default_department_id = departmentStrategy.default_department_id;
        }
        let accountData = this.allDatas.mapping_options.account[0];
        this.create_entity = accountData.strategy.create_entity === "create";
        this.stale_entity =
          accountData.strategy.stale_entity === "keep" ? "keep" : "disable";
        this.update_entity = accountData.strategy.update_entity === "update";
        this.matchers = accountData.matchers.map(item => {
          item.claim =
            field_namesObj[item.claim] !== undefined
              ? field_namesObj[item.claim]
              : item.claim;
          return item;
        });
      }
    },
    add() {
      let newItem = {
        claim: "",
        matcher: "",
        options: {}
      };
      this.matchers.push(newItem);
    },
    remove(curItem, curIndex) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除${curItem.claim}？`,
          callback: () => {
            this.matchers = this.matchers.filter((item, index) => {
              return curIndex !== index;
            });
            setTimeout(
              () => this.$store.commit("show_sheet", { show: true }),
              1
            );
            return new Promise(resolve => resolve());
          }
        })
        .catch(() => {
          return;
        });
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.type === "create") {
          this.reset();
        }
      }
    }
  },
  components: {
    ConfirmBox
  }
};
</script>
