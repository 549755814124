var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"importorg-wrapper"},[(_vm.type !== 'LDAP')?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("同步配置 "),_c('a',{on:{"click":function($event){return _vm.editSyncConfig('SYNC')}}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"col-4"},[_c('span',[_vm._v("企业ID：")]),_c('span',{style:({
              color: _vm.agent_secret ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.corp_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("通讯录同步秘钥：")]),_c('span',{style:({
              color: _vm.agent_secret ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.agent_secret || "未填写")+" ")])]),_c('v-col')],1)],1)],1):_vm._e(),(_vm.type === 'LDAP')?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("同步配置 "),_c('a',{on:{"click":function($event){return _vm.editSyncConfig('LDAPSYNC')}}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("用户名：")]),_c('span',{style:({
              color: _vm.username ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.username || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("密码：")]),_c('span',{style:({
              color: _vm.password ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.password || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("解码方式：")]),_c('span',{style:({
              color: _vm.encoding ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.encoding || "未填写")+" ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("服务地址：")]),_c('span',{style:({
              color: _vm.host ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.host || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("端口号：")]),_c('span',{style:({
              color: _vm.port ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.port || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("启用SSL：")]),_c('span',{style:({
              color: _vm.enable_ssl ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.enable_ssl ? "启用" : "关闭")+" ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("group_base_dn：")]),_c('span',{style:({
              color: _vm.group_base_dn ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.group_base_dn || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("Base_DN：")]),_c('span',{style:({
              color: _vm.base_dn ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.base_dn || "未填写")+" ")])]),_c('v-col')],1)],1)],1):_vm._e(),(0)?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("回调参数")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"col-4"},[_c('span',[_vm._v("启用回调：")]),_vm._v("已启用")]),_c('v-col',{staticClass:"col-8"},[_c('span',[_vm._v("回调URL：")]),_vm._v(" https://iam.richctrl.com/source/ww736adab7f131153d/callback")])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("Token：")]),_vm._v("1wmS9VjjX6OffscJPTVbQw9Ma")]),_c('v-col',[_c('span',[_vm._v("EncodingAESKey：")]),_vm._v("zyWTRoksFIFgrIXxejDx7JULFb5mTo7b1hrStnNTt8R")]),_c('v-col')],1)],1)],1):_vm._e(),_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("同步任务")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","elevation":"1","depressed":""},on:{"click":_vm.runSync}},[_vm._v("立即同步")])],1)],1)],1)],1),_c(_vm.sheet_editor,{tag:"Component",attrs:{"type":"edit","allDatas":_vm.allDatas}}),_c('ConfirmBox',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }