<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            `${$t("source.Edit {type} department config", {
              type: typeTitles[allDatas.type]
            })}：${allDatas.name}`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        组织同步列表项
      </v-alert>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span>根组织</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-select
            :items="departmentTree"
            :label="root_department_id ? '' : '请选择'"
            item-text="name"
            item-value="id"
            v-model="root_department_id"
            @change="getvalue_typeSelected(root_department_id)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span>合并根组织</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-checkbox
            class="v-label"
            v-model="merge_root_department"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span>创建组织</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-checkbox class="v-label" v-model="create_entity"></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span>更新组织</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-checkbox class="v-label" v-model="update_entity"></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span>过期组织</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-radio-group v-model="stale_entity" row>
            <v-radio label="保留组织" value="keep"></v-radio>
            <v-radio label="禁用组织" value="disable"></v-radio>
            <v-radio label="删除组织" value="delete" disabled></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn elevation="4" medium color="primary" @click="save()"
            >提交</v-btn
          >
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "DepartmentSyncEditor",
  props: {
    allDatas: {
      type: Object
    },
    departmentTree: {
      type: Array
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      root_department_id: "2",
      create_entity: true,
      update_entity: true,
      stale_entity: "keep",
      merge_root_department: true,
      typeTitles: {
        WECHAT_ENTERPRISE: "企业微信",
        CAS: "CAS",
        OAUTH2: "OAUTH2",
        CAS_REST_API: "CAS REST API",
        LDAP: "LDAP",
        HTTP_BASIC: "HTTP_BASIC",
        DING_TALK: "钉钉",
        LARK: "飞书"
      }
    };
  },
  created() {
    if (this.type !== "create") {
      this.fetchData();
    }
  },
  methods: {
    getvalue_typeSelected(val) {
      this.root_department_id = val;
    },
    save() {
      if (this.root_department_id !== null) {
        let payload = {
          strategies: {
            root_department_id: this.root_department_id,
            create_entity: this.create_entity === true ? "create" : null,
            update_entity: this.update_entity === true ? "update" : null,
            merge_root_department:
              this.merge_root_department === true ? "merge" : null,
            stale_entity: this.stale_entity
          }
        };
        this.$http
          .patch(
            `api/source/${this.$route.params.id}/mapping_options/DEPARTMENT/strategies`,
            payload
          )
          .delegateTo(api_request)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            bus.$emit("refresh");
            return "更新成功";
          })
          .catch(({ code, message }) => {
            throw `更新失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    reset() {},
    fetchData() {
      if (this.allDatas.mapping_options.department.length > 0) {
        let departmentStrategy = this.allDatas.mapping_options.department[0]
          .strategy;
        this.create_entity = departmentStrategy.create_entity === "create";
        this.stale_entity = departmentStrategy.stale_entity;
        this.update_entity = departmentStrategy.update_entity === "update";
        this.merge_root_department =
          departmentStrategy.merge_root_department === "merge";
        this.root_department_id = departmentStrategy.root_department_id;
      }
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.type === "create") {
          this.reset();
        }
      }
    }
  }
};
</script>
