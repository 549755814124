<template>
  <div class="importorg-wrapper">
    <v-card elevation="0" class="dense" v-if="type !== 'LDAP'">
      <v-card-title
        >同步配置 <a @click="editSyncConfig('SYNC')">(修改)</a></v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col class="col-4"
            ><span>企业ID：</span>
            <span
              :style="{
                color: agent_secret ? '' : '#9195a3'
              }"
            >
              {{ corp_id || "未填写" }}
            </span></v-col
          >
          <v-col
            ><span>通讯录同步秘钥：</span>
            <span
              :style="{
                color: agent_secret ? '' : '#9195a3'
              }"
            >
              {{ agent_secret || "未填写" }}
            </span></v-col
          >
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- LDAP -->
    <v-card elevation="0" class="dense" v-if="type === 'LDAP'">
      <v-card-title
        >同步配置
        <a @click="editSyncConfig('LDAPSYNC')">(修改)</a></v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col>
            <span>用户名：</span>
            <span
              :style="{
                color: username ? '' : '#9195a3'
              }"
            >
              {{ username || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>密码：</span>
            <span
              :style="{
                color: password ? '' : '#9195a3'
              }"
            >
              {{ password || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>解码方式：</span>
            <span
              :style="{
                color: encoding ? '' : '#9195a3'
              }"
            >
              {{ encoding || "未填写" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>服务地址：</span>
            <span
              :style="{
                color: host ? '' : '#9195a3'
              }"
            >
              {{ host || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>端口号：</span>
            <span
              :style="{
                color: port ? '' : '#9195a3'
              }"
            >
              {{ port || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>启用SSL：</span>
            <span
              :style="{
                color: enable_ssl ? '' : '#9195a3'
              }"
            >
              {{ enable_ssl ? "启用" : "关闭" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>group_base_dn：</span>
            <span
              :style="{
                color: group_base_dn ? '' : '#9195a3'
              }"
            >
              {{ group_base_dn || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>Base_DN：</span>
            <span
              :style="{
                color: base_dn ? '' : '#9195a3'
              }"
            >
              {{ base_dn || "未填写" }}
            </span>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="dense" v-if="0">
      <v-card-title>回调参数</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="col-4"><span>启用回调：</span>已启用</v-col>
          <v-col class="col-8"
            ><span>回调URL：</span>
            https://iam.richctrl.com/source/ww736adab7f131153d/callback</v-col
          >
        </v-row>
        <v-row>
          <v-col><span>Token：</span>1wmS9VjjX6OffscJPTVbQw9Ma</v-col>
          <v-col
            ><span>EncodingAESKey：</span
            >zyWTRoksFIFgrIXxejDx7JULFb5mTo7b1hrStnNTt8R</v-col
          >
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="dense">
      <v-card-title>同步任务</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn color="primary" elevation="1" depressed @click="runSync"
              >立即同步</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <Component :is="sheet_editor" type="edit" :allDatas="allDatas" />
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
const EDITORS = {
  SYNC: SyncConfig,
  LDAPSYNC: LdapSyncConfig
};

import { api_request } from "@/util/network";
import SyncConfig from "@/views/source/editor/SyncConfig";
import LdapSyncConfig from "@/views/source/editor/LdapSyncConfig";
import ConfirmBox from "@/components/ConfirmBox";
export default {
  name: "SyncConfig",
  props: ["allDatas", "type"],
  data() {
    return {
      sheet_editor: null,
      drawer: false,
      enabled: true,
      finish_url: null,
      corp_id: null,
      agent_secret: null,
      // LDAP
      base_dn: null,
      enable_ssl: null,
      encoding: null,
      host: null,
      password: null,
      port: null,
      search_filter: null,
      username: null,
      sms_auth: null,
      external_id: null
    };
  },
  created() {
    this.getCurIdData();
  },
  methods: {
    runSync() {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要立即同步？`,
          callback: () => {
            return this.$http
              .post(`api/source/${this.$route.params.id}/run_sync`)
              .delegateTo(api_request)
              .then(() => {
                return "立即同步成功";
              })
              .catch(({ code, message }) => {
                throw `立即同步失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${"api." + JSON.stringify(message)}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    editSyncConfig(type) {
      this.sheet_editor = EDITORS[type];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    getCurIdData() {
      if (this.allDatas.connector_options) {
        let curData = this.allDatas.connector_options;
        this.switchFetchList(this.allDatas.type, curData);
      }

      // LDAP
      if (this.type === "LDAP" || this.type === "FNU_LDAP") {
        this.external_id = this.allDatas.external_id;
        let options = this.allDatas.options;
        if (options) {
          let {
            base_dn,
            enable_ssl,
            encoding,
            host,
            password,
            port,
            search_filter,
            username,
            sms_auth
          } = options;
          this.base_dn = base_dn;
          this.enable_ssl = enable_ssl;
          this.encoding = encoding;
          this.host = host;
          this.password = password;
          this.port = port;
          this.search_filter = search_filter;
          this.username = username;
          if (sms_auth) {
            this.sms_auth = sms_auth;
          }
        }
      }
    },
    switchFetchList(curType, secretData) {
      switch (curType) {
        case "WECHAT_ENTERPRISE":
          this.corp_id = secretData.corp_id;
          this.agent_secret = secretData.agent_secret;
          break;
        case "DING_TALK":
          this.corp_id = secretData.app_key;
          this.agent_secret = secretData.app_secret;
          break;

        case "LARK":
          this.corp_id = secretData.app_id;
          this.agent_secret = secretData.app_secret;
          break;

        default:
          this.agent_secret = "";
          break;
      }
    }
  },
  watch: {
    allDatas() {
      this.getCurIdData();
    }
  },
  components: {
    SyncConfig,
    ConfirmBox
  }
};
</script>

<style lang="less" scoped>
.importorg-wrapper {
  width: 100%;

  .wrapper-one {
    margin-top: 30px;

    .config-content {
      .every-item {
        display: flex;
        align-items: center;
        label {
          flex: 0 0 66px;
          text-align: right;
          font-size: 16px;
          margin-right: 20px;
        }
        /deep/.v-label {
          font-size: 16px;
          margin-right: 100px;
          &.active {
            color: #2998f6;
          }
        }
        select {
          width: 200px;
          height: 30px;
          box-sizing: border-box;
          padding-left: 20px;
          outline: none;
          border: 1px solid #949494;
          border-radius: 4px;
          font-size: 14px !important;
        }
        .addrule {
          margin-left: 16px;
          font-size: 14px;
        }
        .table-wrapper {
          /deep/.v-data-table__wrapper {
            overflow-x: hidden;
          }
          thead {
            tr {
              th {
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
          tbody {
            background-color: #fff;
            select {
              width: 276px;
              height: 30px;
              border: 1px solid #c8ccd0;
              border-radius: 4px;
              outline: none;
              box-sizing: border-box;
              padding: 4px 10px;
            }
            input {
              width: 200px;
              height: 30px;
              border: 1px solid #c8ccd0;
              border-radius: 4px;
              outline: none;
              box-sizing: border-box;
              padding: 4px 10px;
            }
          }
        }
      }
      .orgcontrl {
        display: flex;
        align-items: center;
        .every-item {
          label {
            font-size: 16px;
            color: #717377;
          }
          /deep/.v-label {
            font-size: 16px;
            margin-right: 100px;
            &.active {
              color: #2998f6;
            }
          }
        }
      }
    }
  }
}
</style>
