<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            `${$t("source.Edit {type} sync config", {
              type: typeTitles[allDatas.type]
            })}：${allDatas.name}`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        同步身份源列表项
      </v-alert>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span>企业ID</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="corp_id" rows="1"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader
            ><span class="red--text">*</span> 通讯录同步秘钥</v-subheader
          >
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="agent_secret" rows="1"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn elevation="4" medium color="primary" @click="save">提交</v-btn>
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "SyncConfigEditor",
  props: {
    allDatas: {
      type: Object
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      corp_id: null,
      agent_secret: null,
      typeTitles: {
        WECHAT_ENTERPRISE: "企业微信",
        CAS: "CAS",
        OAUTH2: "OAUTH2",
        CAS_REST_API: "CAS REST API",
        LDAP: "LDAP",
        HTTP_BASIC: "HTTP_BASIC",
        DING_TALK: "钉钉",
        LARK: "飞书"
      }
    };
  },
  created() {
    if (this.type !== "create") {
      this.fetchData();
    }
  },
  methods: {
    save() {
      if (this.agent_secret !== null) {
        let payload = {
          connector_options: {
            corp_id: this.corp_id,
            agent_secret: this.agent_secret
          }
        };
        this.$http
          .patch(`api/source/${this.$route.params.id}/connector`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            bus.$emit("refresh");
            return "更新成功";
          })
          .catch(({ code, message }) => {
            throw `更新失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    reset() {},
    fetchData() {
      if (this.allDatas.connector_options !== null) {
        let curData = this.allDatas.connector_options;
        this.switchFetchList(this.allDatas.type, curData);
      }
    },
    switchFetchList(curType, secretData) {
      switch (curType) {
        case "WECHAT_ENTERPRISE":
          this.corp_id = secretData.corp_id;
          this.agent_secret = secretData.agent_secret;
          break;
        case "DING_TALK":
          this.corp_id = secretData.app_key;
          this.agent_secret = secretData.app_secret;
          break;

        case "LARK":
          this.corp_id = secretData.app_id;
          this.agent_secret = secretData.app_secret;
          break;

        default:
          this.corp_id = "";
          this.agent_secret = "";
          break;
      }
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.type === "create") {
          this.reset();
        }
      }
    }
  }
};
</script>
